<template>
  <div class="mt-12">
    <app-header></app-header>
    <drawer></drawer>
    <div class="flex w-64 flex-col">
      <div class="hidden md:block border-b border-gray-200 h-12">
        <div class="flex px-5 py-2 items-center border-r border-gray-200">
          <p class="px-5 text-lg">{{ titleMenu }}</p>
        </div>
      </div>

      <div
        class="fixed top-12 left-0 bottom-0 hidden md:block w-64 overflow-y-auto overflow-x-hidden
        min-h-full border-r border-b border-gray-200"
      >
        <!-- <div
          class="flex flex-col py-2 min-h-full border-r border-b border-gray-200"
        > -->
        <!-- <router-link v-for="m in menu" :to="m.route" :key="m.id">
            <div
              class="flex w-full py-3 pl-3 rollover-menu"
              :class="{
                'rollover-menu-text-color-active': isCurrentPathActive(m.route)
              }"
            >
              <div class="w-12 pl-3">
                <font-awesome-icon
                  :icon="m.icon"
                  class="rollover-menu-icon"
                  :class="{
                    'rollover-menu-text-color-active': isCurrentPathActive(
                      m.route
                    )
                  }"
                />
              </div>
              <p class="flex-1 text-left text-sm">
                {{ m.title }}
              </p>
            </div>
          </router-link> -->
        <!-- </div> -->
      </div>
    </div>

    <div
      class="fixed top-12 bottom-0 right-0 flex-1 left-0 md:left-64 overflow-y-auto overflow-x-hidden"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import AppHeader from '@/_components/AppHeader'
import Drawer from '@/_components/Drawer.vue'
export default {
  name: 'InnerPage',
  props: {
    menu: {
      type: Array,
      required: true
    },
    titleMenu: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'building'
    }
  },
  components: { AppHeader, Drawer },
  methods: {
    isCurrentPathActive: function(routeToMatch) {
      return this.$router.currentRoute.path === routeToMatch ? true : false
    }
  },
  mounted: function() {}
}
</script>
